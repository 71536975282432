import axios, { AxiosResponse } from "axios";

import { MANAGER_SERVICE_URI, MEDIA_SERVICE_URI, LEGACY_DOMAIN } from "../shared/constants";

const legacyHost = axios.create({
  baseURL: LEGACY_DOMAIN,
});

const mediaHost = axios.create({
  baseURL: MEDIA_SERVICE_URI,
});

const managerHost = axios.create({
  baseURL: MANAGER_SERVICE_URI,
});

mediaHost.interceptors.response.use(successCallback, errorCallback);
legacyHost.interceptors.response.use(successCallback, errorCallback);
managerHost.interceptors.response.use(successCallback, errorCallback);

function successCallback(response: AxiosResponse): any {
  // console.info('[http.interceptors]', response.data);

  return response;
}

function errorCallback(error: any): any {
  const responseAsFailback = error.status !== 409 ? Promise.reject(error) : Promise.resolve({ token: undefined });

  console.info("[http.interceptors : failback]", responseAsFailback);
  return responseAsFailback;
}

export { mediaHost, managerHost, legacyHost };
