import "./VideoTransition.css";
import { ENVIRONMENT_CONTEXT } from "../../shared/constants";
import { ConnectionStatus, Environments, SessionStatus } from "../../shared/enums";

export default function VideoRoomTransition(props: {
  exception: Error;
  roomStatus: SessionStatus;
  connStatus: ConnectionStatus;
}) {
  return (
    <>
      {props.connStatus === ConnectionStatus.RECONNECTING && (
        <div className='reconnectingContent'>
          <label className='reconnectingMessage'>Reconectando a sessão</label>
          {Environments.DEVELOPMENT === ENVIRONMENT_CONTEXT && (
            <div>
              <label>{props.exception?.message}</label>
              <label>{props.exception?.name}</label>
            </div>
          )}
        </div>
      )}
      {props.connStatus === ConnectionStatus.CONNECTED && <></>}
      {props.roomStatus === SessionStatus.Closed && (
        <div className='reconnectingContent'>
          <label className='reconnectingMessage'>Finalizando a sessão</label>
        </div>
      )}
    </>
  );
}
