import '../App.css';
import './Conference.css';
import { useMemo } from 'react';

import { Button } from '@mui/material';
import { EventType } from '../shared/enums';
import { FirebaseProvider } from '../providers/Firebase';
import Header from '../components/HeaderComponent/Header';
import { useLocation, useNavigate } from 'react-router-dom';

export default function RecoveryConferenceContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId, sessionErrorContext } = location.state;

  const firebaseProvider = useMemo(() => new FirebaseProvider(), []);

  firebaseProvider.sendUserEvent(EventType.PAGE_VIEW, {
    page_location: '/restore',
    page_encoding: '',
    page_title: 'Reconexão',
    user_agent: '',
    client_id: sessionId,
    language: '',
  });

  return (
    <div className="App">
      <Header token={sessionId!} />
      <div className="disconnectedContent">
        <label className="disconnectedMessage">{sessionErrorContext?.name}</label>
        <label className="disconnectedMessage">Por favor clique no botão abaixo</label>
        <label>{sessionErrorContext?.detail}</label>
        <Button
          sx={{
            backgroundColor: '#ed9120',
            boxShadow: '2px 2px ',
            padding: '10px',
            color: 'white',
            height: '50px',
            width: '120px',
            margin: 'auto',
          }}
          onClick={() => navigate('/room', { state: { sessionId } })}
        >
          Reconectar
        </Button>
      </div>
    </div>
  );
}
