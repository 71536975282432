export function ErrorFallback(props: { error: Error; resetErrorBoundary: Function }) {
  return (
    <div role="alert">
      <p>Algo deu errado:</p>
      <pre>{props.error.name}</pre>
      <pre>{props.error.message}</pre>
      <button onClick={() => props.resetErrorBoundary()}>Tentar novamente</button>
    </div>
  );
}
