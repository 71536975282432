import { LEGACY_DOMAIN, RELOAD_TIMEOUT_MS } from "../shared/constants";
import { Roles } from "../shared/enums";
import { legacyHost } from "./index";

export async function getTokenInformation(token: string) {
  const response = await legacyHost.post(`/apiToken.cfm?action=getDadosCliente&token=${token}`);

  return response.data;
}

export async function saveEventHistory(message: string, token: string) {
  const payload = { texto: message };
  const response = await legacyHost.post(
    `/apiToken.cfm?action=registraHistorico&token=${token}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  return response;
}

export async function getRemoteLogo(id: string) {
  let response;

  try {
    await legacyHost.get(`planetun-v2/sistema/includes/imagens/regulacoes/${id}/logo_topo.svg`);
    response = `${LEGACY_DOMAIN}/planetun-v2/sistema/includes/imagens/regulacoes/${id}/logo_topo.svg`;
  } catch (error) {
    console.log(error);
    await legacyHost.get(`planetun-v2/sistema/includes/imagens/regulacoes/${id}/logo_topo.png`);
    response = `${LEGACY_DOMAIN}/planetun-v2/sistema/includes/imagens/regulacoes/${id}/logo_topo.png`;
  }

  return response;
}

export async function completeToken(token: string) {
  const response = await legacyHost.get(
    `/apiToken.cfm?action=finalizaAtendimento&token=${token}&user=${Roles.Insured}`
  );
  return response;
}

export function redirectToMobi(token: string) {
  setTimeout(() => {
    window.parent.location.href = `${LEGACY_DOMAIN}/televistoria.cfm?token=${token}`;
  }, RELOAD_TIMEOUT_MS);
}
