import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import defaultLogo from "../../assets/planetun.svg";
import { getTokenInformation, getRemoteLogo } from "../../services/legacyService";

import { useEffect, useState } from "react";
import "./Header.css";

export default function Header(props: { token: string }) {
  const [logo, setLogo] = useState(defaultLogo);
  const [isLoadedProfile, setProfile] = useState(false);

  useEffect(() => {
    if (!isLoadedProfile) {
      const loadCustomerInformation = async () => {
        try {
          const response = await getTokenInformation(props.token);
          const remoteLogo = await getRemoteLogo(response.ID_CLIENTE);

          // TODO: include validations ( Serialization )
          if (response.ID_CLIENTE !== "") {
            setLogo(remoteLogo);
          }
        } catch (error) {
          console.log(error);
        }

        setProfile(true);
      };

      loadCustomerInformation();
    }
  }, [isLoadedProfile]);
  // sx={{ top: '2%', position: 'relative' }}
  return (
    <AppBar position='static' elevation={0}>
      <Toolbar
        sx={{
          backgroundColor: "white",
        }}>
        <img alt='Planetun' className='logo' src={logo} />
      </Toolbar>
    </AppBar>
  );
}
