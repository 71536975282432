import { useState } from 'react';

import './Buttons.css';
import { Roles } from '../../shared/enums';

import { CallEnd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Publisher, Session } from 'openvidu-browser';
import { completeToken } from '../../services/legacyService';
import ModalDialogComponent from '../FeedbackComponent/Feedback';

export default function PowerOffButton(props: {
  session: Session;
  sessionId: string;
  publisher: Publisher;
}) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const navigate = useNavigate();

  async function confirmLeaveSession() {
    setIsOpenDialog(false);

    try {
      await completeToken(props.publisher.session.sessionId);

      props.publisher.session.signal({
        data: Roles.Insured,
        type: 'finished',
      });
    } catch (error) {
      console.warn(error);
    }

    navigate('/feedback', { state: { sessionId: props.sessionId } });
  }

  function cancelLeaveSession() {
    setIsOpenDialog(false);
  }

  return (
    <>
      {isOpenDialog ? (
        <ModalDialogComponent
          isOpenDialog={isOpenDialog}
          confirmLeaveSession={confirmLeaveSession}
          cancelLeaveSession={cancelLeaveSession}
        />
      ) : (
        <></>
      )}
      <CallEnd
        color="success"
        id="navLeaveButton"
        sx={{
          color: 'white',
          fontSize: 25,
          borderRadius: 10,
          padding: 2,
          backgroundColor: 'rgba(255, 0, 0, 0.5)',
        }}
        onClick={() => setIsOpenDialog(true)}
      />
    </>
  );
}
