import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Modal } from '@mui/material';
import Card from '@mui/material/Card';
import './Feedback.css';

export default function ModalDialogComponent(props: any) {
  return (
    <Modal
      open={props.isOpenDialog}
      onClose={props.cancelLeaveSession}
      className="modalDialog"
      sx={{ top: '40%' }}
    >
      <Card id="card">
        <CardContent>
          <Typography color="textSecondary">
            Você têm certeza que deseja finalizar essa chamada?
          </Typography>
        </CardContent>
        <CardActions className="modalCard">
          <Button variant="outlined" size="medium" onClick={props.confirmLeaveSession}>
            Sim
          </Button>

          <Button variant="outlined" size="medium" onClick={props.cancelLeaveSession}>
            Não
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}
