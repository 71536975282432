import { useEffect, useState } from "react";
import { DEFAULT_CAMERA_TYPE } from "../shared/constants";

import { saveEventHistory } from "../services/legacyService";
import { CameraType, Environments, OrientationType } from "../shared/enums";

import {
  ALTERNATIVE_HEIGHT_VIDEO_RESOLUTION,
  DEFAULT_WIDTH_VIDEO_RESOLUTION,
  ENVIRONMENT_CONTEXT,
} from "../shared/constants";

export default function useDeviceCapabilities(props: { sessionId: string; setIsAccessDenied: any }) {
  const [hasVideoAvailable, setVideoAvailability] = useState(false);

  const [defaultCamera, setDefaultCamera] = useState<MediaDeviceInfo>(null!);
  const [currentCameraType, setCurrentCameraType] = useState<CameraType>(null!);

  const [backCameraDevices, setBackCameraDevices] = useState<MediaDeviceInfo[]>([]);
  const [frontCameraDevices, setFrontCameraDevices] = useState<MediaDeviceInfo[]>([]);

  function captureCurrentScreen(video: HTMLVideoElement, canvas: HTMLCanvasElement) {
    const { videoWidth, videoHeight } = video;

    // TODO: compatibility - Android x IOS
    const orientation = window.orientation % 180 === 0 ? OrientationType.Portrait : OrientationType.Landscape;

    console.info("[capturePhotoFromStream]", { canvas, video });
    console.info("[orientation]", { orientation });

    switch (orientation) {
      case OrientationType.Portrait:
        canvas.height =
          videoHeight < ALTERNATIVE_HEIGHT_VIDEO_RESOLUTION ? ALTERNATIVE_HEIGHT_VIDEO_RESOLUTION : videoHeight;
        canvas.width = videoWidth < DEFAULT_WIDTH_VIDEO_RESOLUTION ? DEFAULT_WIDTH_VIDEO_RESOLUTION : videoWidth;
        break;

      case OrientationType.Landscape:
        canvas.height = videoHeight < DEFAULT_WIDTH_VIDEO_RESOLUTION ? DEFAULT_WIDTH_VIDEO_RESOLUTION : videoHeight;
        canvas.width =
          videoWidth < ALTERNATIVE_HEIGHT_VIDEO_RESOLUTION ? ALTERNATIVE_HEIGHT_VIDEO_RESOLUTION : videoWidth;
        break;

      default:
        canvas.height = videoHeight;
        canvas.width = videoWidth;
        break;
    }

    console.info("[video.canvas].videoHeight:", videoHeight, canvas.height);
    console.info("[video.canvas].videoWidth:", videoWidth, canvas.width);
    // const scale = window.devicePixelRatio * 2;
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;

    const ctx = canvas.getContext("2d")!;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas;
  }

  function updateCurrentCamera(type: CameraType, device: MediaDeviceInfo) {
    setCurrentCameraType(type);
    setDefaultCamera(device);
  }

  // TODO: include audioinput validation
  function filterUsableDevice(exp: RegExp, device: MediaDeviceInfo) {
    return device.label.match(exp) && device.kind === "videoinput";
  }

  useEffect(() => {
    const localDevices = async () => {
      if (!defaultCamera) {
        // if (/iPhone/i.test(navigator.userAgent)) {
        // TODO: Handle IOS compatibility
        /* open a generic stream to get permission to see devices - Mobile Safari insists */
        // TODO: validate the pair ( audio | video ) when asking permissions

        if (ENVIRONMENT_CONTEXT === Environments.DEVELOPMENT || ENVIRONMENT_CONTEXT === Environments.HOMOLOG) {
          // window's layout viewport in pixels
          const { innerWidth: width, innerHeight: height } = window;

          console.info({
            severity: "INFO",
            component: "hooks.useDeviceCapabilities",
            message: `Device video dimensions: ${height} x ${width}`,
          });

          saveEventHistory(
            `Segurado: Dimensões de tela de vídeo: ${height} pixels de altura por ${width} pixels de largura.`,
            props.sessionId
          )
            .then((res) => console.info(res.data))
            .catch((err) => console.error(err));
        }

        try {
          await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: "environment",
            },
            audio: true,
          });
        } catch (error) {
          console.warn(error);
          props.setIsAccessDenied(true);
        }

        // }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => filterUsableDevice(/^.{4,35}$/i, device));
        setVideoAvailability(devices.some((device) => filterUsableDevice(/^.{4,35}$/i, device)));

        // TODO: Support the respective languages:
        // TODO: english | portuguese | spanish | french

        const backDevices = devices.filter((item) => filterUsableDevice(/back|traseira|trasero|arrière/i, item));
        const frontDevices = devices.filter((item) => filterUsableDevice(/front|frontal|delantera|face/i, item));

        const alternativeDevices = devices.filter((item) => filterUsableDevice(/^.{4,35}$/i, item));

        console.log({ hardware: { frontDevices, backDevices, alternativeDevices } });

        setBackCameraDevices(backDevices);
        setFrontCameraDevices(frontDevices);

        saveEventHistory(
          `Segurado: Câmeras disponíveis: 
        ${JSON.stringify(videoDevices.map((item) => item.label))} |
        ${JSON.stringify(backDevices[0]?.label)} |
        ${JSON.stringify(frontDevices[0]?.label)}| 
        ${JSON.stringify(alternativeDevices[0]?.label)}`,
          props.sessionId
        );

        if (DEFAULT_CAMERA_TYPE === CameraType.Back && backDevices.length > 0) {
          updateCurrentCamera(CameraType.Back, backDevices[0]);
        } else if (frontDevices.length > 0) {
          updateCurrentCamera(CameraType.Front, frontDevices[0]);
        } else {
          updateCurrentCamera(CameraType.Other, alternativeDevices[0]);
        }
      }
    };

    localDevices();
  }, [defaultCamera]);

  return {
    defaultCamera,
    hasVideoAvailable,
    backCameraDevices,
    frontCameraDevices,
    currentCameraType,
    updateCurrentCamera,
    captureCurrentScreen,
  };
}
