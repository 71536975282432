import { redirectToMobi } from '../services/legacyService';
import Header from '../components/HeaderComponent/Header';
import { FirebaseProvider } from '../providers/Firebase';
import { useLocation } from 'react-router-dom';
import { EventType } from '../shared/enums';
import { useEffect, useMemo } from 'react';
import './Feedback.css';

export default function Feedback() {
  const firebaseProvider = useMemo(() => new FirebaseProvider(), []);

  const location = useLocation();
  const { sessionId } = location.state;

  firebaseProvider.sendUserEvent(EventType.PAGE_VIEW, {
    page_location: '/feedback',
    page_encoding: '',
    page_title: 'Avaliação',
    user_agent: '',
    client_id: '',
    language: '',
  });

  useEffect(() => {
    // TODO: consider aggregating session info for "success" flag inference
    firebaseProvider.sendUserEvent(EventType.LEVEL_END, {
      level_name: 'Fluxo de teleconferência finalizado',
    });

    redirectToMobi(sessionId);
  }, []);

  return (
    <div>
      <Header token={sessionId!} />
      <header className="App-header">
        <div className="feedbackMessage">
          <h2>
            <label>Vistoria Concluída!</label>
          </h2>
          <label>Aguarde, por favor.</label>
        </div>
      </header>
    </div>
  );
}
