import "./Buttons.css";
import { Publisher } from "openvidu-browser";
import { MicNone, MicOff } from "@mui/icons-material";

export default function MicButton(props: { setIsMicActive: Function; publisher: Publisher; isMicActive: boolean }) {
  function micStatusChanged() {
    console.log("publishAudio", !props.isMicActive);
    props.publisher.publishAudio(!props.isMicActive);
    props.setIsMicActive(!props.isMicActive);
  }

  const styledIcon = {
    color: "white",
    fontSize: 25,
    borderRadius: 10,
    padding: 2,
  };

  return props.isMicActive ? (
    <MicNone
      color='primary'
      id='nacMicButton'
      sx={{
        ...styledIcon,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      onClick={micStatusChanged}
    />
  ) : (
    <MicOff
      color='primary'
      id='navMicButton'
      sx={{
        ...styledIcon,
        backgroundColor: "rgba(255, 133, 39, 0.8)",
      }}
      onClick={micStatusChanged}
    />
  );
}
