import { Publisher, PublisherProperties, Session } from 'openvidu-browser';
import { DEFAULT_DEVICE_OPTIONS } from '../shared/constants';
import { OpenViduProvider } from '../providers/Openvidu';
import { CameraType } from '../shared/enums';
import { useEffect, useState } from 'react';

export default function useCameraDevice(props: {
  frontCameraDevices: MediaDeviceInfo[];
  backCameraDevices: MediaDeviceInfo[];
  handleErrorCallback: Function;
  updateCurrentCamera: Function;
  currentCameraType: CameraType;
  provider: OpenViduProvider;
  setPublisher: Function;
  publisher: Publisher;
  session: Session;
  settings?: any;
}) {
  const [isSwitching, setIsSwitching] = useState(false);
  const [remainingDevices, setRemainingDevices] = useState<any>({ back: [], front: [] });

  useEffect(() => {
    if (remainingDevices?.back.length === 0 || remainingDevices?.front.length === 0) {
      const currentCameraDevices = {
        back: props.backCameraDevices,
        front: props.frontCameraDevices,
      };

      console.log({ currentCameraDevices });

      setRemainingDevices(currentCameraDevices);
    }
  }, [props.frontCameraDevices, props.backCameraDevices]);

  async function switchCameraDeviceByPublisher(config: PublisherProperties) {
    const newPublisher = props.provider.initializePublisher(
      undefined,
      config,
      props.handleErrorCallback
    );

    await props.session.unpublish(props.publisher);
    await props.session.publish(newPublisher);
    props.setPublisher(newPublisher);
  }

  async function switchCameraDeviceByMediaTrack(config: PublisherProperties) {
    const newMediaStream = await props.provider.getUserMedia(config);
    await props.publisher.replaceTrack(newMediaStream.getVideoTracks()[0]);
  }

  function changeCameraType(type: CameraType, currentCameraId: string) {
    let newCameraConfig: { type: CameraType; devices: MediaDeviceInfo[] };
    switch (type) {
      case CameraType.Back:
        newCameraConfig = {
          type: CameraType.Front,
          devices: remainingDevices?.front.filter(
            (device: MediaDeviceInfo) => device.deviceId !== currentCameraId
          ),
        };
        break;
      case CameraType.Front:
        newCameraConfig = {
          type: CameraType.Back,
          devices: remainingDevices?.back.filter(
            (device: MediaDeviceInfo) => device.deviceId !== currentCameraId
          ),
        };
        break;
      default:
        newCameraConfig = {
          type: CameraType.Back,
          devices: remainingDevices?.back.filter(
            (device: MediaDeviceInfo) => device.deviceId !== currentCameraId
          ),
        };
    }

    return newCameraConfig;
  }

  async function camPositionChanged() {
    setIsSwitching(true);
    const { publisher, settings, currentCameraType } = props;

    const stream = publisher.stream.getMediaStream();
    const videoSettings = stream.getVideoTracks()[0].getSettings();

    const currentVideoDeviceId = videoSettings.deviceId;
    const { type, devices } = changeCameraType(currentCameraType, currentVideoDeviceId!);

    console.log({ newVideoDevices: devices });

    if (devices?.length > 0) {
      // TODO: include global error handling

      const configuration = {
        ...DEFAULT_DEVICE_OPTIONS,
        videoSource: devices[0]?.deviceId,
        publishVideo: settings.isActiveVideo,
        publishAudio: settings.isActiveAudio,
      };

      console.log({ configuration });

      try {
        await switchCameraDeviceByMediaTrack(configuration);
        props.updateCurrentCamera(type, devices[0]);
        setIsSwitching(false);
      } catch (error) {
        console.log(error);
        await switchCameraDeviceByPublisher(configuration);
        props.updateCurrentCamera(type, devices[0]);
        setIsSwitching(false);
      }
    }
  }

  return { camPositionChanged, isSwitching };
}
