import { FirebaseProvider } from '../../providers/Firebase';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './ErrorFallback';
import { EventType } from '../../shared/enums';
import { useMemo } from 'react';

export default function ErrorBoundaryComponent(props: { container: any; onResetState: any }) {
  const firebaseProvider = useMemo(() => new FirebaseProvider(), []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        firebaseProvider.sendUserEvent(EventType.EXCEPTION, {
          description: `${error.name} | ${error.message} | \n ${info}`,
          fatal: true,
        });
      }}
      onReset={() => {
        props.onResetState();
      }}
    >
      {props.container}
    </ErrorBoundary>
  );
}
