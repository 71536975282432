import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/HeaderComponent/Header';
import { FirebaseProvider } from '../providers/Firebase';
import { EventType } from '../shared/enums';
import { Button } from '@mui/material';
import './Welcome.css';
import '../App.css';

export default function Welcome() {
  const navigate = useNavigate();
  const { session_id } = useParams();
  const firebaseProvider = new FirebaseProvider();

  firebaseProvider.setUserMetadata(session_id!, {
    identity: session_id,
    company: '',
    name: '',
  });

  firebaseProvider.sendUserEvent(EventType.PAGE_VIEW, {
    page_location: '/home',
    page_encoding: '',
    page_title: 'Boas Vindas',
    user_agent: '',
    client_id: '',
    language: '',
  });

  return (
    <div>
      <Header token={session_id!} />
      <header className="App-header">
        <div className="welcomeContent">
          <label className="welcomeMessage">
            Para uma melhor experiência de vídeo, recomendamos que conecte o seu dispositivo ao
            wifi.
          </label>
          <label className="welcomeMessage">
            Ao ser solicitado, autorize os acessos à câmera e microfone do seu dispositivo.
            <br />
            <br />
          </label>
          <label className="welcomeMessage">Vamos começar?</label>
          <Button
            sx={{
              backgroundColor: '#ed9120',
              boxShadow: '2px 2px ',
              padding: '10px',
              color: 'white',
              height: '50px',
              width: '120px',
              margin: 'auto',
            }}
            onClick={() => navigate('/room', { state: { sessionId: session_id } })}
          >
            Acessar
          </Button>
        </div>
        <br />
      </header>
    </div>
  );
}
