import { useEffect, useState } from "react";
import { ConnectionStatus } from "../shared/enums";

// TODO: Decouple connection layers: navigator | signaling plane | media plane

export default function useRTCConnection() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.CONNECTED);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);

      if (!isOnline) {
        console.log(`[useRTCConnection] reconnecting:`, isOnline);
        setConnectionStatus(ConnectionStatus.RECONNECTING);
      }

      if (isOnline) {
        console.log(`[useRTCConnection] connected:`, isOnline);
        setConnectionStatus(ConnectionStatus.CONNECTED);
      }
    };

    window.addEventListener("online", handleStatusChange);

    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return { connectionStatus, setConnectionStatus };
}
