import React, { StrictMode } from 'react';
import Welcome from './containers/Welcome';
import Feedback from './containers/Feedback';

import Recovery from './containers/Recovery';
import VideoConference from './containers/Conference';

import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/restore" element={<Recovery />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/room" element={<VideoConference />} />
        <Route path="/home/:session_id" element={<Welcome />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

if(process.env.REACT_APP_ENVIRONMENT === `homolog`){
  console.log(`Collecting metrics`)
  reportWebVitals(console.info);
}

reportWebVitals();
