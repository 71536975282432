import './Toolbar.css';
import { useEffect, useState } from 'react';
import '../ButtonComponent/Buttons.css';
import { Toolbar } from '@mui/material';
import MicButton from '../ButtonComponent/MicButton';
import ChatButton from '../ButtonComponent/ChatButton';
import CameraButton from '../ButtonComponent/CameraButton';
import PowerOffButton from '../ButtonComponent/PowerOffButton';
import CameraFlipButton from '../ButtonComponent/FlipCameraButton';
import { OrientationType } from '../../shared/enums';

export function ToolbarComponent({
  session,
  publisher,
  sessionId,
  isActiveChat,
  isActiveAudio,
  isActiveVideo,
  setIsMicActive,
  setIsCamActive,
  setIsActiveChat,
  hasUnreadMessages,
  camPositionChanged,
}: any) {
  const [buttons, setButtonsStyle] = useState('buttons');
  const [orientation, setOrientation] = useState('');

  function handleOrientation() {
    if (window.orientation % 180 === 0) {
      setOrientation(OrientationType.Portrait);
    } else {
      setOrientation(OrientationType.Landscape);
    }
  }

  useEffect(() => {
    handleOrientation();
    // TODO: decouple for Android patterns

    if (!/iPhone/i.test(navigator.userAgent)) {
      window.screen?.orientation.addEventListener('change', () => {
        console.log({ event: 'change' });
        handleOrientation();
      });
    }

    // TODO: decouple for IOS patterns
    window.addEventListener('orientationchange', () => {
      console.log({ event: 'orientationchange' });
      handleOrientation();
    });
  }, []);

  useEffect(() => {
    if (isActiveChat && orientation === OrientationType.Landscape) {
      console.log({ orientation, isActiveChat });
      setButtonsStyle('buttons-landscape-active');
    }
    if (isActiveChat && orientation === OrientationType.Portrait) {
      console.log({ orientation, isActiveChat });
      setButtonsStyle('buttons');
    }
    if (!isActiveChat) {
      console.log({ orientation, isActiveChat });
      setButtonsStyle('buttons');
    }
  }, [isActiveChat, orientation]);

  useEffect(() => {
    console.log({ buttons });
  }, [buttons]);

  return (
    <div className="toolbar">
      <Toolbar>
        <div className={buttons}>
          <MicButton
            publisher={publisher}
            isMicActive={isActiveAudio}
            setIsMicActive={setIsMicActive}
          />
          <CameraButton
            publisher={publisher}
            isCamActive={isActiveVideo}
            setIsCamActive={setIsCamActive}
          />
          <CameraFlipButton camPositionChanged={camPositionChanged} />
          <PowerOffButton
            sessionId={sessionId}
            publisher={publisher}
            session={session}
          />
          <ChatButton
            isActiveChat={isActiveChat}
            setIsActiveChat={setIsActiveChat}
            hasUnreadMessages={hasUnreadMessages}
          />
        </div>
      </Toolbar>
    </div>
  );
}
