import { Session, OpenVidu, Publisher, PublisherProperties } from "openvidu-browser";

export class OpenViduProvider extends OpenVidu {
  ov: OpenVidu;
  constructor() {
    super();
    this.ov = new OpenVidu();
  }

  getOpenviduInstance() {
    return this.ov;
  }

  // TODO: https://docs.openvidu.io/en/stable/api/openvidu-browser/interfaces/OpenViduAdvancedConfiguration.html
  initializeSession(): Session {
    this.ov.setAdvancedConfiguration({
      iceConnectionDisconnectedExceptionTimeout: 4000,
      forceMediaReconnectionAfterNetworkDrop: true,
      noStreamPlayingEventExceptionTimeout: 5000,
    });
    return this.ov.initSession();
  }

  initializePublisher(
    element: HTMLElement | string | undefined,
    options: PublisherProperties,
    callback?: any
  ): Publisher {
    return this.ov.initPublisher(element!, options, callback);
  }
}
