import { FIREBASE_CONFIG } from "../shared/constants";

import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { setUserId, setUserProperties, CustomParams } from "firebase/analytics";
import { getStorage, FirebaseStorage, ref, StorageReference } from "firebase/storage";

export class FirebaseProvider {
  private app: FirebaseApp;
  private analytics: Analytics;
  private storage: FirebaseStorage;

  constructor() {
    this.app = initializeApp(FIREBASE_CONFIG);
    this.analytics = getAnalytics(this.app);
    this.storage = getStorage(this.app);
  }

  setUserMetadata(userId: string, userProfile: CustomParams) {
    setUserId(this.analytics, userId);
    setUserProperties(this.analytics, userProfile);
  }

  sendUserEvent(event: string, params: any) {
    logEvent(this.analytics, event, params);
  }

  getStorageRef(blob: Blob, path: string): StorageReference {
    console.log({ blob, path });
    const storageRef = ref(this.storage, path);
    return storageRef;
  }
}
