// https://developers.google.com/tag-platform/gtagjs/reference/events#select_content
// https://developers.google.com/tag-platform/gtagjs/reference/events#level_start
// https://developers.google.com/tag-platform/gtagjs/reference/events#level_end
// https://developers.google.com/tag-platform/gtagjs/reference/events#page_view
// https://developers.google.com/tag-platform/gtagjs/reference/events#exception

export enum EventType {
  SELECT_CONTENT = "select_content",
  LEVEL_START = "level_start",
  LEVEL_END = "level_end",
  PAGE_VIEW = "page_view",
  EXCEPTION = "exception",
}

export enum Environments {
  HOMOLOG = "homolog",
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}

export enum Roles {
  Analyst = "PERITO",
  Insured = "SEGURADO",
}

export enum CameraType {
  Other = "other",
  Front = "front",
  Back = "back",
}

export enum OrientationType {
  Landscape = "landscape",
  Portrait = "portrait",
}

export enum ConnectionStatus {
  RECONNECTING = "reconnecting",
  DISCONNECTED = "disconnected",
  RECONNECTED = "reconnected",
  CONNECTED = "connected",
}

export enum SessionStatus {
  Closed = "closed", // session is over | add finished
  Active = "active", // session is active ( all participants connected )
  Awaiting = "awaiting", // session is not active ( missing participant )
}

export enum Signals {
  Upload = "upload",
  ScreenShot = "screenshot",
  UserChanged = "userChanged",
  MessagingAnalyst = "messaging-analyst",
  MessagingInsured = "messaging-insured",
}
