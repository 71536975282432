import { Button } from '@mui/material';
import './Exception.css';

export default function ErrorPermission(props: { onResetState: any }) {
  return (
    <div className="errorPermissionContent">
      <label className="errorPermissionMessage">
        Por favor autorize o acesso à câmera e ao microfone do seu dispositivo.
        <br />
        <br />
        Você pode realizar esse procedimento através das configurações do seu navegador.
      </label>
      <Button
        sx={{
          backgroundColor: '#ed9120',
          boxShadow: '2px 2px ',
          padding: '10px',
          color: 'white',
          height: '50px',
          width: '120px',
          margin: 'auto',
        }}
        onClick={props.onResetState}
      >
        Reconectar
      </Button>
    </div>
  );
}
