import './Buttons.css';
import { Chat, ChatBubble } from '@mui/icons-material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export default function ChatButton(props: {
  isActiveChat: boolean;
  setIsActiveChat: Function;
  hasUnreadMessages: boolean;
}) {
  const style = {
    padding: 2,
    fontSize: 25,
    color: 'white',
    borderRadius: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  };

  return props.hasUnreadMessages && !props.isActiveChat ? (
    <div className="chat-container">
      <ChatBubble
        color="inherit"
        id="navChatButton"
        sx={{ ...style, animation: 'fadeIn 0.8s infinite alternate' }}
        onClick={() => props.setIsActiveChat(true)}
      />
      <NotificationsActiveIcon
        sx={{
          color: 'rgba(255, 133, 39, 0.8)',
          overflow: 'hidden',
          zIndex: '2',
        }}
      />
    </div>
  ) : (
    <Chat
      color="inherit"
      id="navChatButton"
      sx={style}
      onClick={() => props.setIsActiveChat(!props.isActiveChat)}
    />
  );
}
