import './Buttons.css';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';

export default function CameraFlipButton(props: {
  camPositionChanged: Function;
}) {
  const styledIcon = {
    color: 'white',
    fontSize: 25,
    borderRadius: 10,
    padding: 2,
  };

  return (
    <FlipCameraIosIcon
      color="primary"
      id="navCamFlipButton"
      sx={{
        ...styledIcon,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
      onClick={() => props.camPositionChanged()}
    />
  );
}
