import { Signals } from "../shared/enums";
import { Publisher } from "openvidu-browser";
import { FirebaseProvider } from "./Firebase";

import { UploadTask, getDownloadURL, UploadTaskSnapshot, uploadBytesResumable } from "firebase/storage";

export async function uploadCurrentScreen(props: {
  provider: FirebaseProvider;
  publisher: Publisher;
  payload: any;
  blob: Blob;
}) {
  const { payload, provider, blob, publisher } = props;

  const { unique } = payload;
  const storageRef = provider.getStorageRef(blob, `${unique}.jpg`);

  const uploadTask = uploadBytesResumable(storageRef, blob);
  const response = await handleSnapshotTask(publisher, uploadTask, payload);
  return response;
}

async function onTaskComplete(uploadTask: UploadTask, payload: any) {
  const { data } = payload;
  const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

  return {
    downloadUrl,
    message: `${Signals.ScreenShot}:${data}`,
  };
}

function onTaskChange(publisher: Publisher, snapshot: UploadTaskSnapshot, payload: any) {
  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  console.log("Upload is " + progress + "% done");

  const { data } = payload;

  publisher.session.signal({
    data: `${Math.round(progress)}`,
    type: `${Signals.Upload}:${data}`,
  });

  switch (snapshot.state) {
    case "paused":
      console.log("Upload is paused");
      break;
    case "running":
      console.log("Upload is running");
      break;
  }
}

function onTaskError(error: any, payload: any) {
  const { currentVideoId, unique, data } = payload;

  // A full list of error codes is available at
  // https://firebase.google.com/docs/storage/web/handle-errors
  switch (error.code) {
    case "storage/unauthorized":
      console.log(error);
      throw new Error(`${currentVideoId}-${data}-${unique} | storage/unauthorized`);
    case "storage/canceled":
      console.log(error);
      throw new Error(`${currentVideoId}-${data}-${unique} | storage/canceled`);
    case "storage/unknown":
      console.log(error);
      throw new Error(`${currentVideoId}-${data}-${unique} | storage/unknown`);
  }
}

function handleSnapshotTask(publisher: Publisher, uploadTask: UploadTask, payload: any) {
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot: UploadTaskSnapshot) => {
        onTaskChange(publisher, snapshot, payload);
      },
      (error: any) => {
        try {
          onTaskError(error, payload);
        } catch (error) {
          reject(error);
        }
      },
      async () => {
        const message = await onTaskComplete(uploadTask, payload);
        resolve(message);
      }
    );
  });
}
