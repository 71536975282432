import { Session, SignalEvent } from 'openvidu-browser';
import { IChatMessage } from '../../shared/interfaces';
import { Roles, Signals } from '../../shared/enums';
import { useEffect, useRef, useState } from 'react';

import { Button } from '@mui/material';
import './ChatMessageView.css';

export default function ChatMessageView(props: {
  setUnreadMessages: Function;
  isActiveChat: boolean;
  session: Session;
}) {
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState('');

  function sendMessage(e?: any) {
    const messageFromInsured: IChatMessage = {
      read: false,
      text: input,
      date: new Date(),
      origin: Roles.Insured,
    };

    props.session.signal({
      data: messageFromInsured.text,
      type: Signals.MessagingAnalyst,
    });

    setMessages((messages) => [...messages, messageFromInsured]);
    setInput('');
  }

  function onInputKeyPress(e?: any) {
    setInput((e.target as HTMLTextAreaElement).value);
  }

  function scrollIntoView() {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  useEffect(() => {
    scrollIntoView();
  }, [messages]);

  useEffect(() => {
    if (props.isActiveChat) {
      props.setUnreadMessages(false);
    }
  }, [props.isActiveChat]);

  useEffect(() => {
    messages.find((i) => i.origin === Roles.Analyst && i.read === false)
      ? props.setUnreadMessages(true)
      : props.setUnreadMessages(false);
  }, [messages]);

  useEffect(() => {
    props.session.on(`signal:${Signals.MessagingInsured}`, (event: SignalEvent) => {
      console.log({ useEffect: event });

      const messageText = event.data!;
      const messageTime = event.from?.creationTime!;

      const messageFromAnalyst: IChatMessage = {
        read: props.isActiveChat,
        origin: Roles.Analyst,
        text: messageText,
        date: messageTime,
      };

      setMessages((messages) => [...messages, messageFromAnalyst]);
    });
  }, []);

  return (
    <div className="messageContainer">
      {props.isActiveChat ? (
        <div className="messageBox">
          <div className="messageContent">
            {messages.map((i, k) => {
              return i.origin === Roles.Insured ? (
                <p className="messageText insuredMessage" key={k} ref={messagesEndRef}>
                  {i.text}
                </p>
              ) : (
                <p className="messageText analystMessage" key={k} ref={messagesEndRef}>
                  {i.text}
                </p>
              );
            })}
          </div>
          <div className="messageControl">
            <textarea typeof="text" value={input} onInput={onInputKeyPress} />
            <Button
              disabled={!props.isActiveChat}
              // placeholder={'Enviar'}
              onClick={sendMessage}
              sx={{
                gap: '10px',
                width: '66px',
                margin: 'auto',
                height: '42px',
                color: 'white',
                padding: '15px',
                borderRadius: '8px',
                position: 'relative',
                background: '#FF8527',
              }}
            >
              Enviar
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
