import './Buttons.css';
import { Publisher } from 'openvidu-browser';
import { Videocam, VideocamOff } from '@mui/icons-material';

export default function CameraButton(props: {
  setIsCamActive: Function;
  publisher: Publisher;
  isCamActive: boolean;
}) {
  function camStatusChanged() {
    props.setIsCamActive(!props.isCamActive);
    props.publisher.publishVideo(!props.isCamActive);
  }

  const styledIcon = {
    color: 'white',
    fontSize: 25,
    borderRadius: 10,
    padding: 2,
  };

  return props.isCamActive ? (
    <Videocam
      color="primary"
      id="navCamButton"
      sx={{
        ...styledIcon,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
      onClick={camStatusChanged}
    />
  ) : (
    <VideocamOff
      color="primary"
      id="navCamButton"
      sx={{
        ...styledIcon,
        backgroundColor: 'rgba(255, 133, 39, 0.8)',
      }}
      onClick={camStatusChanged}
    />
  );
}
