import { Publisher, StreamManager } from "openvidu-browser";
import React, { useRef, useEffect } from "react";

interface MediaStreamDisplay {
  title?: string;
  id?: string | number;
  width?: string | number;
  height?: string | number;
  mediaStream: MediaStream | Publisher | StreamManager;
}

export function MediaStreamComponent({ mediaStream }: MediaStreamDisplay) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && mediaStream instanceof StreamManager) {
      mediaStream.addVideoElement(videoRef.current);
    }
    if (videoRef.current && mediaStream instanceof MediaStream) {
      videoRef.current.srcObject = mediaStream;
    }
    if (videoRef.current && mediaStream instanceof Publisher) {
      mediaStream.addVideoElement(videoRef.current);
    }
  }, [mediaStream, videoRef]);

  return (
    mediaStream && (
      <div>
        <video ref={videoRef} autoPlay={true} id={mediaStream.id} data-testid='video' />
      </div>
    )
  );
}
